import * as z from 'zod'
import { useState } from 'react'
import {
  VStack,
  Text,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  useColorMode
} from '@chakra-ui/react'
import { Input } from '@/component/Form/Input'
import { Mail, Lock } from '@/component/Icon'
import NextLink from 'next/link'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useRouter } from 'next/router'
import { signIn } from 'next-auth/react'
import { Toast } from '@/utils/toast'
import { getSubdomain } from '@/services/subdomain'
import { GetServerSideProps } from 'next'
import { getServerSession } from 'next-auth'
import { authOptions } from './api/auth/[...nextauth]'
import { fetchRandomImage } from '@/services/unsplash'
import Image from 'next/image'
import { api } from '@/services/api'
import { TenantsProps, Tenants } from '@/interfaces/Tenants'
import { DEFAULT_TENANT } from '@/utils/contants'

const FormSchema = z.object({
  email: z
    .string({ required_error: 'Campo obrigatório' })
    .email('E-mail inválido')
    .max(100, 'Máximo de 100 caracteres'),
  password: z
    .string({ required_error: 'Campo obrigatório' })
    .min(8, 'A senha deve conter pelo menos 8 caracteres')
    .max(100, 'Máximo de 100 caracteres')
})

type FormDataProps = z.infer<typeof FormSchema>

interface Props {
  tenant: Tenants
}

export default function Login({ tenant }: Props) {
  const { colorMode } = useColorMode()
  const router = useRouter()
  const [isLoadingAccount, setIsLoadingAccount] = useState(false)

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm<FormDataProps>({
    resolver: zodResolver(FormSchema)
  })

  const handleLogin = async (values: FormDataProps) => {
    try {
      setIsLoadingAccount(true)
      let res = await signIn('credentials', {
        email: values.email,
        password: values.password,
        callbackUrl: (router.query?.callbackUrl as string) ?? `/dashboard`,
        redirect: false
      })

      if (res?.ok) {
        reset()
        Toast({
          title: 'Bem vindo(a)!',
          status: 'success'
        })
        if (tenant.url !== 'gptmax') {
          await router.push(`/chat`)
        } else {
          await router.push(res.url || '/dashboard')
        }
      } else {
        reset({ password: '' })
        Toast({
          title: 'Acesso a conta',
          description:
            'Não foi possível acessar a sua conta, verifique os dados e acesso e tente novamente.',
          status: 'warning'
        })
      }
    } catch (e) {
      Toast({
        title: 'Acesso a conta',
        description:
          'Parece que houve uma falha ao tentar acessar sua conta, tente novamente em alguns minutos.',
        status: 'error'
      })
    } finally {
      setIsLoadingAccount(false)
    }
  }

  return (
    <Grid
      h="100vh"
      w="100%"
      bgImage={tenant?.image_background}
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize="cover"
      templateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
      p={8}
      position={'relative'}
    >
      <GridItem>
        <Flex w={'full'} h={'full'} justify={'center'} alignItems={'center'}>
          <VStack
            spacing={8}
            w="full"
            maxW="430px"
            bg={'rgba(255, 255, 255, 0.9)'}
            backdropFilter={'blur(20px)'}
            borderRadius="xl"
            p={[4, 8]}
            boxShadow="4px 4px 4px 0px #00000040"
            borderColor={`${colorMode}.primary.container`}
            borderWidth={1}
          >
            <Image
              src={tenant?.logo as string}
              width={256}
              height={32}
              alt="Company Logo"
              priority
            />

            <Text
              fontWeight="bold"
              fontSize="2xl"
              color={`${colorMode}.primary.onContainer`}
            >
              Acesse sua conta
            </Text>
            <VStack
              as="form"
              onSubmit={handleSubmit(handleLogin)}
              spacing={6}
              w="full"
            >
              <Input
                {...register('email')}
                type="email"
                leftElement={
                  <Mail
                    w="22px"
                    h="22px"
                    stroke={`${colorMode}.primary.main`}
                  />
                }
                placeholder="E-mail"
                error={errors.email}
              />
              <Input
                {...register('password')}
                type="password"
                securityEntry
                placeholder="Senha"
                leftElement={
                  <Lock
                    w="22px"
                    h="22px"
                    stroke={`${colorMode}.primary.main`}
                  />
                }
                error={errors.password}
              />
              <Button
                size="md"
                variant="solid"
                type="submit"
                isLoading={isSubmitting || isLoadingAccount}
                loadingText="Aguarde"
                w="full"
              >
                Entrar
              </Button>
            </VStack>

            <Flex w="full" align="center" gap="11px">
              <Divider borderColor={`${colorMode}.primary.onContainer`} />
              <Text color={`${colorMode}.primary.onContainer`}>ou</Text>
              <Divider borderColor={`${colorMode}.primary.onContainer`} />
            </Flex>

            <Flex fontSize="sm" justify="center" align="center">
              <NextLink href="/recovery-password" passHref>
                <Text color={`${colorMode}.primary.main`} fontWeight="bold">
                  Esqueci a senha
                </Text>
              </NextLink>
            </Flex>

            <Flex
              w="full"
              justifyContent="center"
              alignItems="center"
              flexDir="column"
              gap={1}
            >
              <Text
                color={`${colorMode}.primary.onContainer`}
                fontWeight={'400'}
                fontSize={'10px'}
              >
                Powered by
              </Text>
              <Image
                width={164}
                height={12}
                src="/logo-abstrato.svg"
                alt="Abstrato Logo"
              />
            </Flex>
          </VStack>
        </Flex>
      </GridItem>
    </Grid>
  )
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const session = await getServerSession(context.req, context.res, authOptions)
  if (session) {
    return {
      props: {},
      redirect: {
        destination: '/dashboard'
      }
    }
  }

  try {
    const jwt = process.env.API_TOKEN

    const host = context.req.headers.host
    const subdomain = getSubdomain(host as string)

    if (subdomain === 'gptmax' || subdomain === 'development' || !subdomain) {
      const backgroundImageSrc = await fetchRandomImage()

      return {
        props: {
          tenant: {
            name: 'gptmax',
            image_background: backgroundImageSrc,
            logo: '/logo-gptmax.svg',
            logo_extended: null
          }
        }
      }
    } else {
      const { data } = await api.get<TenantsProps>(
        `/api/tenants/subdomain/${subdomain}/`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        }
      )

      return {
        props: {
          tenant: data ?? DEFAULT_TENANT
        }
      }
    }
  } catch {
    return {
      props: {
        tenant: DEFAULT_TENANT
      }
    }
  }
}
