import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input as ChakraInput,
  InputGroup,
  InputLeftElement,
  InputProps as ChakraInputProps,
  InputRightElement,
  Text,
  useColorMode
} from '@chakra-ui/react'
import {
  forwardRef,
  ForwardRefRenderFunction,
  ReactElement,
  useState
} from 'react'

import { FieldError } from 'react-hook-form'
import { EyeClose, EyeOpen } from '@/component/Icon'

interface IInputProps extends ChakraInputProps {
  securityEntry?: boolean
  notEmpty?: boolean
  leftElement?: ReactElement
  rightElement?: ReactElement
  name: string
  label?: string
  error?: FieldError
  component?: ReactElement
  formLabelColor?: string
  bRadius?: string
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, IInputProps> = (
  {
    name,
    label,
    type,
    error = null,
    securityEntry = false,
    notEmpty = false,
    component,
    formLabelColor = 'grey.100',
    leftElement,
    rightElement,
    bRadius = '6px',
    ...rest
  },
  ref
) => {
  const [passwordHidden, setPasswordHidden] = useState(true)
  const { colorMode } = useColorMode()

  return (
    <FormControl isInvalid={!!error}>
      <Flex justify={'space-between'} align={'center'}>
        {!!label && (
          <FormLabel
            fontSize={'sm'}
            color={`${colorMode}.primary.main`}
            mb={'8px'}
            fontWeight={'bold'}
            htmlFor={name}
            id={name}
          >
            {label}
            {notEmpty ? (
              <Text as={'span'} color={`${colorMode}.error.main`}>
                *
              </Text>
            ) : null}
          </FormLabel>
        )}
        <>{!!component && component}</>
      </Flex>
      <InputGroup>
        {leftElement ? (
          <InputLeftElement h={'48px'} pl={2}>
            {leftElement}
          </InputLeftElement>
        ) : rightElement ? (
          <InputRightElement h={'48px'} pr={2}>
            {rightElement}
          </InputRightElement>
        ) : null}
        <ChakraInput
          type={securityEntry && !passwordHidden ? 'text' : type}
          id={name}
          name={name}
          h={'48px'}
          borderRadius={'6px'}
          errorBorderColor={`${colorMode}.error.main`}
          borderColor={`${colorMode}.primary.container`}
          bgColor={`${colorMode}.background`}
          color={`${colorMode}.primary.onContainer`}
          _focus={{
            borderColor: `${colorMode}.primary.main`,
            bgColor: `${colorMode}.background`
          }}
          _hover={{
            borderColor: `${colorMode}.primary.main`,
            bgColor: `${colorMode}.background`
          }}
          _active={{
            borderColor: `${colorMode}.primary.main`,
            bgColor: `${colorMode}.background`
          }}
          _focusVisible={{
            borderColor: `${colorMode}.primary.main`,
            bgColor: `${colorMode}.background`
          }}
          _disabled={{
            bgColor: `${colorMode}.primary.container`,
            cursor: 'not-allowed',
            _hover: {
              borderColor: `${colorMode}.primary.container`
            }
          }}
          ref={ref}
          autoComplete={'off'}
          pl={leftElement ? '46px' : '16px'}
          {...rest}
        />
        {securityEntry && (
          <InputRightElement pr={2} h={'48px'}>
            <Button
              variant={'link'}
              onClick={() => setPasswordHidden(!passwordHidden)}
            >
              {passwordHidden ? (
                <EyeOpen stroke={'grey.300'} w={'24px'} h={'24px'} />
              ) : (
                <EyeClose stroke={'grey.300'} w={'24px'} h={'24px'} />
              )}
            </Button>
          </InputRightElement>
        )}
      </InputGroup>
      {!!error && (
        <FormErrorMessage color={`${colorMode}.error.main`} fontSize={'xs'}>
          {error.message}
        </FormErrorMessage>
      )}
    </FormControl>
  )
}

export const Input = forwardRef(InputBase)
